/** _base.scss **/

//---------------------- Basic --------------------- //

body {
  line-height: $line-height-base;
  color: $color-default;
  font-size: $font-size-base;
  font-family: $body-font;
  font-weight: normal;
  display: table;
  width: 100%;
  background: $background-color;
}

//---------------------- TYPO --------------------- //
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}
h1 {
  font-size: $font-size-h1
}
h2 {
  font-size: $font-size-h2;
}
h3 {
  font-size: $font-size-h3;
}
h4 {
  font-size: $font-size-h4;
}
h5 {
  font-size: $font-size-h5;
}
h6 {
  font-size: $font-size-h6;
}


//---------------------- HELPER --------------------- //
.bg-dark {
  background: $color-dark;
}
.bg-light {
  background: $color-light;
}
.bg-brand {
  background: $color-grey;
}
.red {
  color: red;
}
.green {
  color: green;
}
.orange {
  color: orange;
}

//---------------------- FONTAWESOME --------------------- //
.priority {
  padding: 0.2em;
  color: white;
}
.priority.red {
  background: red;
}
.priority.orange {
  background: orange;
}
.priority.green {
  background: green;
}

//---------------------- BUTTONS --------------------- //
.btn-primary {
  background: $color-primary;
  color: white;
  border: 1px solid $color-primary;
  &:hover {
    background: $color-primary-darker;
    border: 1px solid $color-primary-darker;
  }
}
.btn-info {

}

.btn-warning {
  background: $color-warning;
  color: white;
  border: 1px solid $color-warning;
}

.btn-danger {
  background: $color-danger;
  color: white;
  border: 1px solid $color-danger;
}

.btn-black {
  background: black;
  color: white;
  border: 1px solid black;
  &:hover {
    color: white;
  }
}

//---------------------- SIDEBAR NAV --------------------- //
ul.sidebar-nav {
  h6 {
    padding: 0.25em 0.5em 0.25em 1em;
    font-size: $font-size-h4;
    color: white;
    background-color: $color-primary;
    font-weight: normal;
  }
  list-style-type: none;
  padding: 0;
  margin: 1em 0em 1em 0;

  li {
    padding: 0.25em 0.5em 0.25em 1em;
    display: block;
    border-bottom: 1px solid $color-primary;

    &:hover {
      text-decoration: none;
      background: $color-primary;
      color: white;
      border-bottom: $color-primary;
    }

    a {
      display: block;
      color: $color-default;
      i {
        font-size: $font-size-base*0.9;
        width: 30px;
      }

      &:hover {
        text-decoration: none;
        color: white;
      }
    }
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.notification {
  overflow: hidden;
  z-index: 9999;
  background: red;
  display: flex;
  width: 100%;
  &.show {
    position: absolute;
  }
}

//---------------------- LINKS --------------------- //
a {
  color: $color-primary;
  text-decoration: none;
  &:hover, &:focus {
    text-decoration: underline;
  }
}

body.external {
  .right-panel .pageheader-container-inner .triggerMobileNav:not(.hide) {
    display: none !important;
  }
  .right-panel .pageheader-container-inner > .row {
    margin-left: 0 !important;
  }
}

//---------------------- Alert --------------------- //
.alert-error {
  background: red;
  color: white;
}

.alert-success {
  background: green;
  color: white;
}