@media (max-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {

}

// Lower Resolution than 1200px
@media (max-width: 1200px) {

  // Hide Left Panel
  .left-panel:not(.sidebar-open) {
    width: 0;
    overflow: hidden;
  }

  .right-panel:not(.sidebar-open) {
    margin-left: 0;
  }

  .left-panel.sidebar-open {
    width: 280px;
    overflow: inherit;
  }
  .right-panel.sidebar-open {
    margin-left: 280px;
  }

  // Show Menu Button
  .right-panel {
    .pageheader-container-inner {
      position: relative;
      .triggerMobileNav:not(.hide) {
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        z-index: 1000;
      }
      > .row {
        margin-left: 20px !important;
      }
    }
  }
}