/** _form.scss **/

form {
  label.required:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  label {
    font-size: $font-size-base*0.9;
    font-weight: 700;
  }
  textarea {
    min-height: 10em;
  }

  .form-check.form-check-inline {
    min-width: 14em;
    background: $color-grey-light;
    padding-right: 0.75rem;
  }
  label.suggestedUser {
    display: flex;
  }

  .form-row-suggesteduser {
    margin-bottom: 1.5rem;
  }

  .error-missingfields {
    border: 1px solid red;
  }

  .form-group .administration {

  }

  .markedAsToCopy {
    background: lightgoldenrodyellow;
  }

  .markedAsEmpty {
    background: #f79d6d;
  }

  .form-control.required {
    border: 1px solid red;
  }
}

// Forms Datasheet