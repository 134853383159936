@media print {

}

body.print {

  background: white;

  h1 {
    font-size: 24pt;
    border-bottom: 2px solid grey;
    color: black;
  }

  h2 {
    font-size: 16pt;
    margin-bottom: 10pt;
    color: black;
  }

  h3 {
    font-size: 12pt !important;
    background: lightgray;
    font-style: italic;
  }
  h3.subtitle {
    color: red;
  }


  span.fieldlabel {
    display: block;
    font-weight: bold;
    font-size: 10pt;
  }
  li.list-group-item {
    span.name {
      display: block;
      font-weight: bold;
    }
  }



}