/** _layout.scss **/

//---------------------- Master Layout --------------------- //
.left-panel {
  height: 100vh;
  padding: 0;
  vertical-align: top;
  width: 280px;
  position: fixed;
  left:0;
  bottom: 0;
  top: 90px;
  z-index: 999;
  background: white;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .version {
    position: absolute;
    bottom: 6em;
    font-size: $font-size-base*0.9;
    padding: 0 1em;
    color: lightgray;
    border-top: 1px solid lightgray;
  }
}

.right-panel {
  margin-left: 280px;
  margin-top: 90px;
  header.header {
    background: $color-primary;
    border-bottom: 1px solid $color-dark;
    clear: both;
    padding: 0 30px;
    height: 90px;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    .top-left {
      width: 350px;
      float: left;
    }
    .top-right {
      .header-menu {
        display: flex;
        justify-content: flex-end;
        .header-left {
          display: inline-block;
        }
      }
    }
  }

  .content {
    float: left;
    padding: 1.875em;
    width: 100%;
  }
}


//---------------------- LOGIN --------------------- //
.login-content {
  max-width: 540px;
  margin: 8vh auto;
  .login-logo {
    text-align: center;
    padding: 1em;
    background: $color-primary;
  }
  .login-form{
    background: white;
    padding: 1em;
    input {
      margin-bottom: 1em;
    }
  }

  .version {
    border-top: 1px solid $color-grey;
    background: white;
    padding: 0.5em 1em;
    font-size: $font-size-base*0.9;
    color: lightgray;
    width: 100%;

   }

}
