/** _customvar.scss **/

// -------------- FONTS --------------- //
$body-font:                     'Raleway', sans-serif;

// -------------- FONTSIZE --------------- //
$font-size-base:                18px;
$font-size-h1:                  36px;
$font-size-h2:                  30px;
$font-size-h3:                  24px;
$font-size-h4:                  18px;
$font-size-h5:                  $font-size-base;
$font-size-h6:                  12px;
// -------------- COLOR --------------- //
$color-primary:                 #cb142e;
$color-primary-darker:          darken($color-primary, 10);
$color-secondary:               #5bb030;
$color-default:                 #000;
$color-dark:                    #343a40;
$color-light:                   #fafafa;
$color-grey:                    #eeeeee;
$color-grey-light:              #f2f2f2;
$color-danger:                  #ff0000;
$color-danger-light:                  #ff8080;
$color-warning:                 #ff9900;
$color-succes:                  #ace600;

$background-color:              #f1f2f7;

// -------------- SPECIFIC COLOR STYLES --------------- //
$color-partinprogress:              #ddd;
$color-partcompleted:               #32d141;

// -------------- LINE HEIGHT --------------- //

$line-height-base:              1.5;
$line-height-content:           1.8;