/** _widgest.scss **/

// ---------------------- Dashboard Settings ------------------//
form.dashboardSettings {
  display: none;
  &.dashboardSettingToggled {
    display: block !important;
  }
}

.card.settings.dashboardSettingToggled {
  position: absolute;
  z-index: 9999;
  background: $color-grey-light;

}

// --------------------- nav nav-tabs --------------------- //
ul.nav.nav-tabs {
  .nav-item {
    background: $color-partinprogress;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    margin-right: 1px;
    a {
      color: black;
    }
  }

}

//---------------------- Different Styles --------------------- //

.layernotedit.show {
  position: absolute;
  background: rgba(220,160,140,0.5);
  height: 100%;
  width: 100%;
  z-index: 99;
}
section {
  margin-bottom: 2em;
}
.dashboard-header {
  float: left;
  margin-top: 5px;
  width: 100%;
  .inner {
    background: white;
    .page-title h1 {
      font-size: $font-size-h3;
      padding: 0.5em;
      margin-bottom: 0;
    }
  }
}

table.table {
  .col_1 {
    width: 30px;
  }
  .col_2 {
    width: 30px;
  }
  .background-red {
    background-color: red;
    color: white;
    font-weight: bold;
  }
}

.pageheader-container {
  // float: left;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0;
  width: 100%;
  .pageheader-container-inner {
    background: white;
    .page-title h1 {
      font-size: $font-size-h3;
      padding: 0.5em;
      margin-bottom: 0;
    }
  }
  .page-header {
    min-height: 50px;
    display: flex;
    align-items: center;
  }
}

.card.nobg {
  background-color: transparent;
  border: 1px solid transparent;
}
.nav.nav-pills {
  .nav-link {
    margin-right: 0.25em;
  }
  a.nav-link {
    color: white;
    &:hover {
      background: white;
      color: black;
      border: 1px solid rgba(0, 0, 0, 0.03);
    }
  }
  .nav-link {
    background: $color-default;
  }
  .nav-link.active {
    background-color: rgba(0, 0, 0, 0.03);
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid transparent;
  }
  border-radius: 5px;
}

//---------------------- Card Styles --------------------- //
.card.danger {
  .card-header {
    background-color: red;
    color: white;
  }
}

//---------------------- Card Widgets --------------------- //
.stat-widget {
  .stat-icon {
    font-size: 50px;
    line-height: 50px;
    position: absolute;
    left: 30px;
    top: 20px;
  }
  .stat-content {
  }
}
.stat-widget-60 {
  min-height: 50px;
  .stat-content {
    margin-left: 100px;
  }
}

//---------------------- Comments --------------------- //
.comments {
  .form-group > textarea {
    margin-top: 1em;
  }
  label {
    display: none;
  }
}

//---------------------- Tables --------------------- //
table.table {
  thead > tr > th {
    font-weight: 400;
    padding: 0.25em 0.75em;
  }
}

//---------------------- Tasks --------------------- //
.tasks {
  .table {
    font-size: $font-size-base*0.9;
    td.action {
      width: 80px;
      display: flex;
    }
  }
  table tr.passed-deadline {
    background-color: red;
    color: white;
  }
  table tr.passed-deadline-workflow {
    background-color: $color-danger-light;
    color: white;
  }

}


//---------------------- Task Modal --------------------- //
.taskModal {
  .modal-dialog.modal-lg {
    max-width: 1280px;
  }
  .modal-overlay {
    position: absolute;
    background: white;
    padding: 1.875em;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    height: 100vh;
  }
  .modal-content {
    overflow: scroll;
    height: 80vh;
  }
  .card-deck table.table {
    tbody tr td:nth-of-type(1) {
      background: $background-color;
      width: 180px;
    }
  }
  .history {
    height: 200px;
    overflow: scroll;
    table.table {
      font-size: $font-size-base*0.8;
      thead tr th, tbody tr td {
        padding: 0.1em 0.2em;
      }
    }
  }
  .taskcomments label {
    display: none;
  }
  .tasklogs {
    .collapseelement {
      margin-top: 1em;
    }
    .entrys {
      display: none;
    }
    .entrys.open {
      display: block;
    }
  }
  .taskrelated {

  }

}
.projectFieldRelatedTaskModal {
  .modal-dialog {
    max-width: 800px;
  }

}
.createNewTask {
  .modal-dialog.modal-lg {
    max-width: 1280px;
  }
}


.hide {
  display: none;
}

//---------------------- Project --------------------- //
.project {
  legend {
    display: none;
  }
  legend.show {
    margin-top: 1em;
    display: block;
    &.small {
      font-size: $font-size-base*1.1;
      background: $gray-200;
      padding-left: 0.5em;
    }
  }

  label.useAsTask {
    a {
      padding: 0 0.25em 0 0;
    }
    a:first-of-type {
      margin-left: 1em;
    }
  }

  label.useAsTask.labelSpecialChoicefield {
    position: absolute;
    right: 0.5em;
  }

  label.useAsTask > .innerLabel {
    position: absolute;
    right: 0.5em;
    top: 0;
  }

  ul.notes {
    list-style-type: none;
    margin: 0 0 2em 0;
    padding-left: 1em;
    width: 100%;
    li {
      position: relative;
      margin-right: 3em;
      border-bottom: 1px solid lightgray;
      .remove-remark {
        position: absolute;
        top: 3.5em;
        right: -2em;
      }
    }
    li:not(.origin) > div  {
      display: flex;
      flex-direction: row;
      .form-group {
        padding: 1em;
        flex-basis: 100%;
      }
    }
    li.origin {
      display: flex;
      flex-direction: row;
      > .form-items {
        padding: 1em;
        flex-basis: 100%;
      }
    }
  }
}

.badge-red > i {
  color: red;
}

.badge-orange > i {
  color: orange;
}

.badge-green > i {
  color: green;
}


//---------------------- Project List --------------------- //
.project-list {
  tr {
    border-bottom: 1px solid grey;
  }

  // TD color Styles different States
  td.partInProgress {
    width: 200px;
    background: $color-partinprogress;
    border-right: 1px solid black
  }

  td.inProcess {
    background: #fbddac;
  }
  td.completed {
    background: #dbf3a6;
  }
  td.projectParts {
    border-right: 1px solid grey;
    width: 135px;
  }
  td.edit {
    width: 165px;
  }
  td.initialized {
    width: 145px;
  }
}

//---------------------- Uploader --------------------- //
.uploader {
  max-height: 600px;
  overflow: scroll;
}
.dropzone-uploader {
  .dropzone-button {
    display: none;
    &.show {
      display: flex;
    }
    .btn {
      cursor: pointer;
    }
  }

  .dropzone-waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 24em;
    cursor: pointer;
  }
  .inner {
    i {
      color: $color-primary;
      font-size: 6em;
    }
  }

  .dz-preview {
    display: flex;
    margin-bottom: 0.5em;
    > div {
      margin-right: 1em;
      span.preview > img {
        border: 1px solid grey;
      }
    }
    &.dz-success {
      background-color: greenyellow;
    }
  }
}

.uploader-collaboration {
  max-height: 200px;
  overflow: scroll;
  .dropzone-waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 6em;
    cursor: pointer;
  }
  .inner {
    i {
      color: $color-primary;
      font-size: 2em;
    }
  }
}

// -------------------- Files List in Project --------------- //
.project-files-list {
  margin-top: 1em;
  .toggle-icon {
    margin-right: 10px;
  }

  ul.thumbnail-pdfimages {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 5px;
    }
  }
}

// -------------------- Files Uploaded an Edit Model -------- //
.modal-files-edit {
  &.modal-dialog {
    min-width: 75%;
  }
  .modal-content {
    min-height: 80vh;
  }
}

// -------------------- Workflow Task ------------------- //
.workflowTask {
  span.form-content, span.form-label {
    display: block;
  }
  span.form-content {
    font-weight: bold;
  }
  span.form-label {
    font-style: italic;
  }

  .comment {
    span.taskcomment-label, span.taskcomment-content {
      display: block;
    }
    span.taskcomment-label {
      font-size: $font-weight-base*0.9;
      font-style: italic;
    }
    border-bottom: 1px solid lightgray;
    margin-bottom: 10px;
  }
}

// ---------------- Verification --------------------- //
.verification-form {
  .new-comment textarea {
    width: 716px;
    display: block;
    height: 10em;
  }
  .new-comment > div > .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// --------------- Notes ------------------------ //
.hasNotes {
  i {
    color: blue;
  }
}

.notes {
  .card-body > .d-flex {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 5px;
    > small > i {
      margin-right: 3px;
      margin-left: 3px;
    }
  }
}

// --------------- CK Editior ---------------- //
.cke.cke_reset {
  width: 100%;
}

// --------------- Card Collaborate ---------- //
.collaborate-edit {
  .collaborate-head {
    background: $color-grey-light;
    padding: 0.5em 0.75em;
  }

  .comments {
    .list-group-item {
      position: relative;
      padding-top: 4em;
      .comment-header {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        background: $color-grey-light;
        padding: 0.25em;
      }
    }

  }
}

// ------------- Collaborate Table ------------ //
table.table-collaborat-list {
  .start {
    width: 20px;
  }
  .changed {
    width: 150px;
  }
  .type {
    width: 80px;
  }
  .created {
    width: 150px;
  }
  .title {

  }
}

//
.triggerMobileNav {
  display: none;
}

// ---------- Modal generell ------- //
.modal {
  .modal-header {
    background: $color-grey-light;
  }
}

// ---------- Container Collapes -------- //
legend.show {
  padding: 0.25em;
  &.completed {
    cursor: pointer;
    background: $color-grey-light;
    border-radius: 2px;
    margin-bottom: 0;
    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f14a";
      margin-right: 20px;
      color: $color-primary
    }

  }
}
.collapse-container {
  padding: 1em;
  &.notcomplete {
    background: palevioletred;
  }

  &.completed {
    background: palegreen;
  }
}

.taskcollapse {
  cursor: pointer;
}


span.headline {
  display: block;
  font-weight: bold;
}

// ------------- Search Staff Form ------------- //
.searchStaff {
  margin-bottom: 10px;
}

// ------------- Search Staff List ------------- //
.searchStaff.resultContainer {
  margin-bottom: 10px;
}

.searchResult {
  position: relative;

  .message-container {
    display: block;
  }

  .resultContainer {
    position: absolute;
    overflow: hidden;
    top: 0;
    width: 75%;
    z-index: 1000;
  }
}

.staffList.resultContainer {
  th a {
    color: $color-dark
  }
}

// ----------- Mission Controller -------------- //
.missionStaffComplete {
  background: $color-partcompleted;
}
.missionStaffNotComplete {
  background: $color-danger-light
}

// ----------- Staff List -------------- //
.staffListOverview {
  .item {
    cursor: pointer;
  }
}

.staffDetail {
  strong.mission-title {
    display: block;
    margin-bottom: 10px;
  }
  .subheadline {
    border-bottom: 1px solid $color-grey;
    margin-bottom: 10px;
  }
}

// ----------- System Messages Modal -------------- //
.system-message {
  .modal-content {
    font-weight: 400;
  }
}