/**
 * dev.eventmanager for Contao Open Source CMS
 *
 * Copyright (C) 2019 47GradNord - Agentur fÃ¼r InternetlÃ¶sungen
 *
 * @license    commercial
 * @author     Holger Neuner
 */

table .overdue {
  background: $color-danger-light !important;
}



