/**
 * dev.baur-prtms for Contao Open Source CMS
 *
 * Copyright (C) 2019 47GradNord - Agentur fÃ¼r InternetlÃ¶sungen
 *
 * @license    commercial
 * @author     Holger Neuner
 */

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";
@import "~jquery-datetimepicker/jquery.datetimepicker.css";


// Own Styles
@import "custom/customvar";
@import "custom/base";
@import "custom/layout";
@import "custom/header";
@import "custom/form";
@import "custom/widgets";
@import "custom/eventmanager";
@import "custom/print";
@import "custom/querys";