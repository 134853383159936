/** _header.scss **/

// ---------------- HEADER RECHTS -------------- //
.user-area {
  ul li {
    display: inline-block;
    line-height: 55px;
    color: white;
    margin-left: 5px;
    a {
      min-height: 55px;
      i {
        color: white;
      }
    }
    a.dropdown-toggle:after {
      border: 0;
      &:hover {
        color: red;
      }
    }

  }
  .dropdown-menu {
    min-width: 20rem;
  }
  ul.dropdown-menu {
    padding: 0  !important;
    > li {
      display: block;
      line-height: 30px;
      margin-left: 0 !important;
      padding-left: 1em;
      padding-right: 1em;
      &:hover {
        background: $color-primary;
      }
    }
    > li > a {
      color: black;
      font-size: 14px;
      > i {
        color: black;
      }
      &:hover {
        text-decoration: none;
      }

    }
  }
}